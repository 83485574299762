import Alpine from 'alpinejs'

document.addEventListener('alpine:init', () => {
    Alpine.store('questions', [{
        question: "¿Cuales son nuestros metodos de pago?",
        answer: "Tenemos cubiertos los metodos de pagos más utilizados para su comodidad, aceptamos bancos Pichincha, Guayaquil, pagos Paypal, Visa/Mastercard y PayPhone ."
    },
    {
        question: "¿Nuestros productos/servicios incluyen garantía?",
        answer: "Todos nuestros productos/servicios se encuentran cubiertos por nuestra garantía."
    },
    {
        question: "¿Nuestros productos/servicios cubren soporte técnico?",
        answer: "Si, todos nuestros productos/servicios incluyen soporte tecnico sin costo alguno."
    },
    {
        question: "¿En qué tiempo se entregan nuestros productos/servicios?",
        answer: "Nos encargamos que reciba su producto/servicio seleccionado(s) al instante una vez se haya verificado el pago."
    },
    ]);

    Alpine.store('reviews', [{
        marquee: "500ms",
        data: [{
            stars: 5,
            title: "Excelente servicio",
            leading: "Excelente servicio, 100% recomendado, muy confiables, les dejo mis 5 estrellas ⭐⭐⭐⭐⭐.",
            author: "Joel Armijos"
        },
        {
            stars: 4,
            title: "Buenos precios",
            leading: "Definitivamente nunca espere encontrar tan buenos precios aqui, felicidades 🙌.",
            author: "Joseph Garcia"
        },
        {
            stars: 5,
            title: "Excelente confianza",
            leading: "Nunca me sentí tan seguro al efectivizar mi saldo PayPal ✌️.",
            author: "Josue Velfin"
        },
        {
            stars: 3,
            title: "Excelente atencion",
            leading: "Ni mi madre me trata con tanto amor 🤣, orgulloso de permancer a la familia Jaenar Online.",
            author: "Elian Garcia"
        },
        {
            stars: 4,
            title: "Atencion inmediata",
            leading: "Necesitaba urgente una cuenta de Netflix para verla junto a una pizza que habia encargado 🍕, logré verla con la pizza aún caliente, muchas gracias  🫶.",
            author: "Joseph Garcia"
        },
        {
            stars: 5,
            title: "Atención inmediata",
            leading: "Muy buen servicio, obtuve mi cuenta en pocos minutosm, lo recomiendo al 100%, muchas gracias 👏.",
            author: "Joseph Garcia"
        },
        ]
    },
    {
        marquee: "800ms",
        data: [{
            stars: 3,
            title: "Excelente atencion",
            leading: "Ni mi madre me trata con tanto amor 🤣, orgulloso de permancer a la familia Jaenar Online.",
            author: "Elian Garcia"
        },

        {
            stars: 4,
            title: "Buenos precios",
            leading: "Definitivamente nunca espere encontrar tan buenos precios aqui, felicidades 🙌.",
            author: "Joseph Garcia"
        },
        {
            stars: 5,
            title: "Excelente servicio",
            leading: "Excelente servicio, 100% recomendado, muy confiables, les dejo mis 5 estrellas ⭐⭐⭐⭐⭐.",
            author: "Joel Armijos"
        },
        {
            stars: 4,
            title: "Atencion inmediata",
            leading: "Necesitaba urgente una cuenta de Netflix para verla junto a una pizza que habia encargado 🍕, logré verla con la pizza aún caliente, muchas gracias  🫶.",
            author: "Joseph Garcia"
        },
        {
            stars: 5,
            title: "Excelente confianza",
            leading: "Nunca me sentí tan seguro al efectivizar mi saldo PayPal ✌️.",
            author: "Josue Velfin"
        },
        {
            stars: 5,
            title: "Atención inmediata",
            leading: "Muy buen servicio, obtuve mi cuenta en pocos minutosm, lo recomiendo al 100%, muchas gracias 👏.",
            author: "Joseph Garcia"
        },
        ]
    },
    {
        marquee: "500ms",
        data: [{
            stars: 5,
            title: "Excelente servicio",
            leading: "Excelente servicio, 100% recomendado, muy confiables, les dejo mis 5 estrellas ⭐⭐⭐⭐⭐.",
            author: "Joel Armijos"
        },
        {
            stars: 3,
            title: "Excelente atencion",
            leading: "Ni mi madre me trata con tanto amor 🤣, orgulloso de permancer a la familia Jaenar Online.",
            author: "Elian Garcia"
        },
        {
            stars: 5,
            title: "Atención inmediata",
            leading: "Muy buen servicio, obtuve mi cuenta en pocos minutosm, lo recomiendo al 100%, muchas gracias 👏.",
            author: "Joseph Garcia"
        },
        {
            stars: 4,
            title: "Buenos precios",
            leading: "Definitivamente nunca espere encontrar tan buenos precios aqui, felicidades 🙌.",
            author: "Joseph Garcia"
        },
        {
            stars: 4,
            title: "Atencion inmediata",
            leading: "Necesitaba urgente una cuenta de Netflix para verla junto a una pizza que habia encargado 🍕, logré verla con la pizza aún caliente, muchas gracias  🫶.",
            author: "Joseph Garcia"
        },
        {
            stars: 5,
            title: "Excelente confianza",
            leading: "Nunca me sentí tan seguro al efectivizar mi saldo PayPal ✌️.",
            author: "Josue Velfin"
        },
        ]
    },

    ]);

    Alpine.store('prices', {
        tab: "netflix",
        plans: {
            netflix: [{
                title: "Para 1 dispositivo",
                leading: "Plan basico para un dispositivo, plan también disponible en $3.00 /mes.",
                price: '$4.00',
                features: [
                    "Puedes ver en 1 dispositivo compatible a la vez",
                    "Películas, series y juegos móviles sin límite",
                    "Series y películas sin anuncios",
                    "Descarga en 1 dispositivo compatible a la vez",
                    "Calidad HD",
                ]
            },
            {
                title: "Para 2 dispositivos",
                leading: "Plan standard para dos dispositivo, disfruta tu contenido Full HD.",
                price: '$7.00',
                features: [
                    "Puedes ver en 2 dispositivos compatibles a la vez",
                    "Películas, series y juegos móviles sin límite",
                    "Series y películas sin anuncios",
                    "Descarga en 2 dispositivos compatibles a la vez",
                    "Calidad Full HD",
                ]
            },
            {
                title: "Para 3 dispositivos",
                leading: "Plan standard para tres dispositivo, disfruta tu contenido Full HD.",
                price: '$10.00',
                features: [
                    "Puedes ver en 3 dispositivos compatibles a la vez",
                    "Películas, series y juegos móviles sin límite",
                    "Series y películas sin anuncios",
                    "Descarga en 4 dispositivos compatibles a la vez",
                    "Calidad Ultra HD",
                ]
            },
            {
                title: "Para 4 dispositivos",
                leading: "Plan premium para tres dispositivo, disfruta tu contenido Full HD.",
                price: '$14.00',
                features: [
                    "Puedes ver en 4 dispositivos compatibles a la vez",
                    "Películas, series y juegos móviles sin límite",
                    "Series y películas sin anuncios",
                    "Descarga en 4 dispositivos compatibles a la vez",
                    "Calidad Ultra HD",
                ]
            },
            ],
            disneyPlus: [{
                title: "Para 1 dispositivo",
                leading: "Con Disney+ transmite los últimos estrenos que llegan del cine, originales y clásicos.",
                price: '$2.00',
                features: [
                    "Disponible para 1 dispositivo compatible a la vez.",
                    "Contenido de Disney, originales y clasicos.",
                    "Series y películas sin anuncios.",
                    "Calidad HD."
                ]
            },
            {
                title: "Para 2 dispositivos",
                leading: "Junta Disney+ y Star+. Ahorra y disfruta ahora de los dos servicios por un precio único.",
                price: '$3.50',
                features: [
                    "Disponible para 2 dispositivos al mismo tiempo.",
                    "Contenido de Disney, originales y clasicos.",
                    "Series y películas sin anuncios.",
                    "Calidad HD."
                ]
            },
            {
                title: "Para 3 dispositivos",
                leading: "Disney+, Star+ y STARZPLAY en un plan especial. Compra ahora los tres servicios juntos.",
                price: '$4.50',
                features: [
                    "Disponible para 3 dispositivos al mismo tiempo.",
                    "Contenido de Disney, originales y clasicos.",
                    "Contenido de Star+, originales y clasicos.",
                    "Contenido de STARZPLAY, originales y clasicos.",
                    "Series y películas sin anuncios.",
                    "Calidad HD y Ultra HD",
                ]
            },
            {
                title: "Para 4 dispositivos",
                leading: "Disney+, Star+ y STARZPLAY en un plan especial. Compra ahora los tres servicios juntos.",
                price: '$6.00',
                features: [
                    "Disponible para 4 dispositivos al mismo tiempo.",
                    "Contenido de Disney, originales y clasicos.",
                    "Contenido de Star+, originales y clasicos.",
                    "Contenido de STARZPLAY, originales y clasicos.",
                    "Series y películas sin anuncios.",
                    "Calidad HD y Ultra HD",
                ]
            },
            ],
            starPlus: [{
                title: "Para 1 dispositivo",
                leading: "Star+ es donde encuentras tus estrenos de películas, series y eventos deportivos favoritos de ESPN.",
                price: '$4.00',
                features: [
                    "Disponible para 1 dispositivo.",
                    "Contenido exclusivo FX, Freeform, Hulu, ABC Signature, 20th Century Studios",
                    "Contenido deportivo ESPN",
                    "Series y películas sin anuncios.",
                    "Calidad HD",
                ]
            },
            {
                title: "Para 4 dispositivos",
                leading: "Disney+, Star+ y STARZPLAY en un plan especial. Compre ahora los tres servicios juntos.",
                price: '$7.00',
                features: [
                    "Disponible para 4 dispositivos",
                    "Contenido exclusivo FX, Freeform, Hulu, ABC Signature, 20th Century Studios",
                    "Contenido deportivo ESPN",
                    "Contenido de Disney, originales y clasicos.",
                    "Contenido de Star+, originales y clasicos.",
                    "Contenido de STARZPLAY, originales y clasicos.",
                    "Series y películas sin anuncios.",
                    "Calidad HD y FULL HD",
                ]
            }
            ],
            hboMax: [{
                title: "Para 1 dispositivo",
                leading: "Todos tus favoritos de HBO. Contenido ilimitado. Más de 13000 horas de series, películas y programación exclusiva.",
                price: '$2.00',
                features: [
                    "Disponible para 1 dispositivo.",
                    "Disfruta solo en Smartphones y Tabletas.",
                    "Contenido en definición estándar.",
                    "Descarga y disfruta tus títulos preferidos donde sea.",
                ]
            },
            {
                title: "Para 2 dispositivos",
                leading: "Todos tus favoritos de HBO. Contenido ilimitado. Más de 13000 horas de series, películas y programación exclusiva.",
                price: '$3.50',
                features: [
                    "Disponible para 2 dispositivos.",
                    "Disfruta en todas tus pantallas.",
                    "Chromecast y Airplay disponibles.",
                    "Contenido en alta definición y 4K.",
                    "Descarga y disfruta tus títulos preferidos donde sea.",
                ]
            },
            {
                title: "Para 3 dispositivos",
                leading: "Todos tus favoritos de HBO. Contenido ilimitado. Más de 13000 horas de series, películas y programación exclusiva.",
                price: '$6.00',
                features: [
                    "Disponible para 3 dispositivos.",
                    "Disfruta en todas tus pantallas.",
                    "Chromecast y Airplay disponibles.",
                    "Contenido en alta definición y 4K.",
                    "Descarga y disfruta tus títulos preferidos donde sea.",
                ]
            },
            ],
            amazonPrime: [{
                title: "Para 1 dispositivos",
                leading: "All the basics for starting a new business",
                price: '$2.00',
                features: [
                    "Disponible para todos los dispostivos.",
                    "Contenido en definición estándar.",
                    "Descarga y disfruta tus títulos preferidos donde sea.",
                ]
            },
            {
                title: "Para 2 dispositivos",
                leading: "All the basics for starting a new business",
                price: '$3.50',
                features: [
                    "Disponible para 2 dispositivos.",
                    "Disponible para todos los dispostivos.",
                    "Descarga y disfruta tus títulos preferidos donde sea.",
                ]
            },
            {
                title: "Para 3 dispositivos",
                leading: "All the basics for starting a new business",
                price: '$6.00',
                features: [
                    "Disponible para 3 dispositivos.",
                    "Disponible para todos los dispostivos.",
                    "Contenido en definición estándar.",
                    "Descarga y disfruta tus títulos preferidos donde sea.",
                    "Calidad Ultra HD.",
                ]
            },
            ],
            spotify: [{
                title: "Para 1 dispositivo",
                leading: "Disfruta de tus podcasts, millones de canciones y otros contenidos de creadores de todo el mundo.",
                price: '$3',
                features: [
                    "Reproducción aleatoria",
                    "Escucha sin conexión",
                    "Sin interrupciones publicitarias",
                    "Saltos ilimitados",
                    "Audio de alta calidad",
                ]
            },
            {
                title: "Para 1 dispositivo (3 meses)",
                leading: "Disfruta de tus podcasts, millones de canciones y otros contenidos de creadores de todo el mundo.",
                price: '$7',
                features: [
                    "Reproducción aleatoria",
                    "Escucha sin conexión",
                    "Sin interrupciones publicitarias",
                    "Saltos ilimitados",
                    "Audio de alta calidad",
                ]
            },
            ],
            magisTV: [{
                title: "Para 1 dispostivos",
                leading: "Disfruta más de 80 canales de deportes disponibles",
                price: "$4.00",
                features: [
                    "+936 canales de todos los géneros, contenido premium y eventos.",
                    "+9000 peliculas de todos los géneros.",
                    "+6000 series de todos los géneros.",
                    "Paquetes Premiun /  Deportivo / Adultos",
                ]
            },
            {
                title: "Para 2 dispostivos",
                leading: "Disfruta más de 80 canales de deportes disponibles",
                price: "$7.00",
                features: [
                    "+936 canales de todos los géneros, contenido premium y eventos.",
                    "+9000 peliculas de todos los géneros.",
                    "+6000 series de todos los géneros.",
                    "Vincula 2 dispositivos por cuenta",
                    "Paquetes Premiun /  Deportivo / Adultos",
                ]
            },
            {
                title: "Para 3 dispostivos",
                leading: "Disfruta más de 80 canales de deportes disponibles",
                price: "$9.00",
                features: [
                    "+936 canales de todos los géneros, contenido premium y eventos.",
                    "+9000 peliculas de todos los géneros.",
                    "+6000 series de todos los géneros.",
                    "Vincula 3 dispositivos por cuenta",
                    "Paquetes Premiun /  Deportivo / Adultos",
                ]
            },
        ],
            galaxyTV: [{
                title: "Para 1 dispositivos",
                leading: "All the basics for starting a new business",
                price: '$5.00',
                features: [
                    "Disponible para Android, iOS",
                    "Compatible con Google Chromecast",
                    "Streaming sin cortes con un máximo de 1080p",
                    "Calidad HD y FULL HD",
                ]
            },
            {
                title: "Para 3 dispositivos",
                leading: "All the basics for starting a new business",
                price: '$9.00',
                features: [
                    "Disponible para Android, iOS",
                    "Compatible con Google Chromecast",
                    "Streaming sin cortes con un máximo de 1080p",
                    "Calidad HD y FULL HD",
                ]
            },
        ],
            legazyTV: [{
                title: "Para 1 dispositivo",
                leading: "All the basics for starting a new business",
                price: '$5.00',
                features: [
                    "Disponible para 1 dispositivo",
                    "Canales SD Y HD",
                    "Series y peliculas",
                    "Canales para adultos (opcional)",
                    "Canales PPV y Eventos PPV",
                ]
            },
            {
                title: "Para 3 dispositivos",
                leading: "All the basics for starting a new business",
                price: '$9.00',
                features: [
                    "Disponible para 3 dispositivos",
                    "Canales SD Y HD",
                    "Series y peliculas",
                    "Canales para adultos (opcional)",
                    "Canales PPV y Eventos PPV",
                ]
            },
            ],
            teleLatino: [{
                title: "Para 1 dispositivo",
                leading: "Disfruta tu contenido latino en alta calidad.",
                price: '$4.00',
                features: [
                    "Disponible para 1 dispositivo",
                    "Canales en vivo: +1200",
                    "Contenido VOD: +280 000",
                    "SD(480p), HD(720p) y FHD(1080p)",
                    "HOT: Canales y VOD",
                ]
            },
            {
                title: "Para 3 dispositivos",
                leading: "Disfruta tu contenido latino en alta calidad.",
                price: '$6.00',
                features: [
                    "Disponible para 3 dispositivo",
                    "Canales en vivo: +1200",
                    "Contenido VOD: +280 000",
                    "SD(480p), HD(720p) y FHD(1080p)",
                    "HOT: Canales y VOD",
                ]
            },
            ]
        }
    });
});

window.Alpine = Alpine;
Alpine.start();